import React, { useContext, useEffect, useState } from 'react'
import { Progress, Steps } from 'antd'
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import client from 'apollo'
// import moment from 'moment'
import { useHistory } from 'react-router-dom'

// import { useQuery } from '@apollo/react-hooks'
// import { GET_ADVISORY_TIME } from 'modules/AdvisoryTime/graphql/Query'
// import { GET_CURRENT_USER } from 'auth/graphql/Queries'

import './verification.css'
import KycDoc from './KycDoc'
import KycForm from './KycForm'
import RiskProfile from './RiskProfile'
import { AppContext } from 'AppContext'
import AgreementSign from './AgreementSign'
import ClientProfile1 from './ClientProfile1'
import ClientProfile2 from './ClientProfile2'
import RiskProfileForm from './RiskProfileForm'
import SelectTypeForm from './SelectTypeForm'
import InvestmentPlanning from './InvestmentPlanning'
import EMandateRegistration from './EMandateRegistration'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import Summary from './Summary'
import ClientProfileNon1 from './ClientProfileNon1'
import ClientProfileNon2 from './ClientProfileNon2'
import Congratulations from './Congratulations'
import PendingBookedAdvisory from './PendingBookedAdvisory'

const { Step } = Steps

const Verification = () => {
  const history = useHistory()
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext)
  const { state } = useContext(AppContext)
  const { completedSteps, isPanCardIndividual } = state?.currentUser
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [header, setHeader] = useState(true)

  // const { data: advisoryData, loading: advisoryTimeLoading } = useQuery(
  //   GET_ADVISORY_TIME,
  //   {
  //     fetchPolicy: 'network-only',
  //   }
  // )

  // useEffect(() => {
  //   if (!advisoryTimeLoading) {
  //     setInterval(() => {
  //       if (
  //         moment
  //           .utc(advisoryData?.getAdvisoryTime?.startTime, 'hh:mm A')
  //           .add(2, 'minutes')
  //           .format('LTS') > moment().format('LTS') &&
  //         currentUser?.completedSteps === 'E_MANDATE' &&
  //         currentUser?.isBookingAdvisoryDateSelected
  //       ) {
  //         client
  //           .query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
  //           .then((res) => {
  //             if (res?.data?.currentUser?.completedSteps === 'CONGRATULATION') {
  //               window.location.reload()
  //             }
  //           })
  //           .catch((err) => console.log(err))
  //       }
  //       return () => clearInterval()
  //     }, [10000])
  //   }
  // }, [advisoryTimeLoading])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    window.location.hash = 'no-back-button'
    window.location.hash = 'Again-No-back-button'
    window.onhashchange = function () {
      window.location.hash = 'no-back-button'
    }
  }, [])

  const stepsIndexList = {
    LOGIN_CREATED: 1,
    SELECT_USER_TYPE: 2,
    CLIENT_PROFILE_1: 3,
    CLIENT_PROFILE_2: 4,
    RISK_PROFILE: 5,
    SUMMARY: 6,
    KYC_DOC: 7,
    KYC_FORM: 8,
    INVESTMENT_PLANNING: 9,
    AGREEMENT_SIGN: 10,
    E_MANDATE: 11,
    CONGRATULATION: 12,
    DONE: 13,
  }
  let tempCurrent = 0
  switch (completedSteps) {
    case 'LOGIN_CREATED':
      tempCurrent = 1
      break
    case 'SELECT_USER_TYPE':
      tempCurrent = 2
      break
    case 'CLIENT_PROFILE_1':
      tempCurrent = 3
      break
    case 'CLIENT_PROFILE_2':
      tempCurrent = 4
      break
    case 'RISK_PROFILE':
      tempCurrent = 5
      break
    case 'SUMMARY':
      tempCurrent = 6
      break
    case 'KYC_DOC':
      tempCurrent = 7
      break
    case 'KYC_FORM':
      tempCurrent = 8
      break
    case 'INVESTMENT_PLANNING':
      tempCurrent = 9
      break
    case 'AGREEMENT_SIGN':
      tempCurrent = 10
      break
    case 'E_MANDATE':
      tempCurrent = 11
      break
    case 'CONGRATULATION':
      tempCurrent = 12
      break
    case 'DONE':
      tempCurrent = 13
      break
    default:
      break
  }
  const [current, setCurrent] = useState(tempCurrent)
  const next = (nextStep = 1) => {
    if (
      current === 5 &&
      currentUser.reEvaluate === true &&
      currentUser.cvlStatus === true
    ) {
      setCurrent(9)
    } else if (current === 5 && currentUser.cvlStatus === true) {
      setCurrent(8)
    } else if (
      (current === 7 && currentUser.reEvaluate) ||
      (current === 7 && currentUser?.isReUploadKyc)
    ) {
      let updatedData = { completedSteps: 'DONE' }
      if (currentUser.reEvaluate) {
        updatedData.reEvaluate = false
      } else if (currentUser?.isReUploadKyc) {
        updatedData.isReUploadKyc = false
        updatedData.completedSteps = currentUser?.currentCompletedSteps
      }
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            data: updatedData,
            id: parseInt(currentUser.id),
          },
        })
        .then((res) => {
          dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
          if (
            currentUser.reEvaluate ||
            res.data.updateUser?.completedSteps === 'DONE'
          ) {
            dispatch({ type: 'IS_VERIFICATION_DONE', data: true })
            history.push('/')
          } else if (currentUser?.isReUploadKyc) {
            setCurrent(stepsIndexList[res?.data?.updateUser?.completedSteps])
          }
        })
        .catch((err) => console.log(err))
    } else {
      setCurrent(current + nextStep)
    }
  }

  const back = (backStep = 1) => {
    if (currentUser?.completedSteps === 'RISK_PROFILE') {
      setCurrent(current - backStep)
    } else if (currentUser?.completedSteps === 'CONGRATULATION') {
      setCurrent(11)
    }
  }

  const steps = [
    {
      title: 'Disclaimer',
      content: <RiskProfileForm next={next} />,
    },
    {
      title: 'Select Type',
      content: <SelectTypeForm next={next} />,
    },
    {
      title: 'Client Basic Details',
      content: (
        <>
          {isPanCardIndividual ? (
            <ClientProfile1 next={next} reEvaluate={currentUser.reEvaluate} />
          ) : (
            <ClientProfileNon1
              next={next}
              reEvaluate={currentUser.reEvaluate}
            />
          )}
        </>
      ),
    },
    {
      title: 'Client Profile',
      content: (
        <>
          {isPanCardIndividual ? (
            <ClientProfile2 next={next} reEvaluate={currentUser.reEvaluate} />
          ) : (
            <ClientProfileNon2
              next={next}
              reEvaluate={currentUser.reEvaluate}
            />
          )}
        </>
      ),
    },
    {
      title: 'Risk Profile Assessment',
      content: <RiskProfile next={next} reEvaluate={currentUser.reEvaluate} />,
    },
    {
      title: 'Summary',
      content: (
        <Summary back={back} next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Kyc Document',
      content: <KycDoc next={next} reEvaluate={currentUser.reEvaluate} />,
    },
    {
      title: 'Kyc E-Sign',
      content: <KycForm next={next} reEvaluate={currentUser.reEvaluate} />,
    },
    {
      title: 'Investment Planning',
      content: (
        <InvestmentPlanning next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Agreement Sign',
      content: (
        <AgreementSign next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'E-Mandate Registration',
      content: (
        <EMandateRegistration next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Congratulations',
      content: (
        <Congratulations next={next} reEvaluate={currentUser.reEvaluate} />
      ),
    },
    {
      title: 'Pending Advisory',
      content: (
        <PendingBookedAdvisory
          next={next}
          back={back}
          reEvaluate={currentUser.reEvaluate}
        />
      ),
    },
  ]

  return (
    <>
      <div className='.gx-app-login-content.content-wrapper'>
        {header && windowWidth <= 480 ? (
          <div className='steps_collapse'>
            <Steps current={true} size='small'>
              <Step key={steps[current].title} title={steps[current].title} />
            </Steps>
            <div className='steps_icon' onClick={() => setHeader(!header)}>
              <DownCircleOutlined className='steps_icon_span' />
            </div>
          </div>
        ) : (
          <div>
            <div className='steps_icon' onClick={() => setHeader(!header)}>
              <UpCircleOutlined
                className='steps_icon_span'
                style={windowWidth <= 480 ? {} : { display: 'none' }}
              />
            </div>
            <Steps size='small' current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
        )}
        <div className='progress'>
          <Progress
            percent={Math.round((100 / 14) * (tempCurrent + 1))}
            format={(p) => `${p}% Progress`}
          />
        </div>
        <div className='steps-content'>{steps[current]?.content}</div>
      </div>
    </>
  )
}

export default Verification
