import React, { useContext, useEffect, useState } from 'react'
import { Button, DatePicker, Modal, Statistic } from 'antd'
import WorldImage from 'assets/images/world-image.png'
import { QuestionOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_NSE_HOLIDAYS } from 'modules/NSEHoliday/graphql/Query'
import Page404 from 'components/Page404'
import './verification.css'
import { Link } from 'react-router-dom'
import BookAdvisoryModal from './BookAdvisory/BookAdvisoryModal'
import { AppContext } from 'AppContext'
import client from 'apollo'
import {
  COMPLETE_STEP,
  CREATE_BOOK_ADVISORY,
  GENERATE_ADVISORY,
} from './graphql/Mutations'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import BookedAdvisorySlot from './BookAdvisory/BookedAdvisorySlot'
import { GET_ADVISORY_TIME } from 'modules/AdvisoryTime/graphql/Query'
import { uniq } from 'lodash'
import { GET_SP_AUTO_ADVISE } from './graphql/Queries'

const Congratulations = ({ next }) => {
  const { Countdown } = Statistic
  const [date, setDate] = useState(moment())
  const [openModal, setOpenModal] = useState(false)
  const [isTimeUp, setIsTimeUp] = useState(false)
  const [clientData, setClientData] = useState({})
  const [advisoryLoading, setAdvisoryLoading] = useState(false)

  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext)

  const [mandateId, setMandateId] = useState(currentUser?.mandateId)
  const [isAdvisoryBooked, setIsAdvisoryBooked] = useState(
    currentUser?.isBookingAdvisoryDateSelected ? true : false
  )
  const [userData, setUserData] = useState(currentUser)
  const [amcAmount, setAmcAmount] = useState(0)
  const { data, loading, error } = useQuery(GET_ALL_NSE_HOLIDAYS, {
    fetchPolicy: 'network-only',
  })
  const [isDisableDate, setIsDisableDate] = useState(false)

  const {
    data: advisoryTime,
    loading: advisoryTimeLoading,
    error: advisoryTimeError,
  } = useQuery(GET_ADVISORY_TIME, { fetchPolicy: 'network-only' })

  useEffect(() => {
    setUserData(currentUser)
  }, [currentUser])

  useEffect(() => {
    getSpData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSpData = () => {
    client
      .query({
        query: GET_SP_AUTO_ADVISE,
        variables: {
          where: {
            dontBuyEquity: 'NO',
            exposure: currentUser?.initialExposure,
            planId: parseInt(currentUser?.plan?.id),
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        res.data.spAutoAdvise.forEach((item) => {
          if (Object.keys(item).includes('GrossAMC')) {
            if (!currentUser?.umrnNumber) {
              const eMandateDiscount = item?.GrossAMC * (5 / 100)
              const finalGrossAmount =
                item?.GrossAMC - Math.round(eMandateDiscount)
              let gst = 0
              if (currentUser?.stateId === 4030) {
                const cGst = finalGrossAmount * (9 / 100)
                gst = Math.round(cGst) * 2
              } else {
                gst = finalGrossAmount * (18 / 100)
              }
              setAmcAmount(Math.round(finalGrossAmount + gst))
            } else {
              setAmcAmount(Math.round(item?.GrossAMC + item?.GST))
            }
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  let dates = []
  if (!loading && data?.getHoliday.length) {
    dates = data?.getHoliday?.map((item) => item?.date)
  }

  useEffect(() => {
    const current = moment()
    if (
      current.day() === 0 ||
      current.day() === 6 ||
      dates.some((date) => moment(date).isSame(current, 'day'))
    ) {
      setIsTimeUp(true)
      setIsDisableDate(true)
    }
  }, [dates])

  let array = []

  const disabledDate = (current) => {
    const isWeekend = current.day() === 0 || current.day() === 6
    const holiday = dates.some((date) => moment(date).isSame(current, 'day'))
    const isToday = current.isSame(moment(), 'day')
    const beforeMarketOpen =
      isToday &&
      current < moment(advisoryTime?.getAdvisoryTime?.startTime, 'hh:mm A')
    const afterMarketClose =
      isToday &&
      current > moment(advisoryTime?.getAdvisoryTime?.endTime, 'hh:mm A')

    if (moment(current) >= moment() && array?.length < 5) {
      if (!holiday && !isWeekend && !beforeMarketOpen && !afterMarketClose) {
        array.push(moment(current).format('DD/MM'))
        array = uniq(array)
      }
    }
    return !array?.includes(moment(current).format('DD/MM'))
  }

  let nextAvailableDate
  const findNextAvailableDate = (currentDate = moment()) => {
    const marketCloseTime = moment(
      advisoryTime?.getAdvisoryTime?.endTime,
      'hh:mm A'
    )

    if (
      (moment(currentDate)?.format('DD-MM-YYYY') ===
        moment()?.format('DD-MM-YYYY') &&
        moment(marketCloseTime).format() <= moment().format()) ||
      // eslint-disable-next-line no-loop-func
      dates.filter((date) => date === currentDate?.format('YYYY-MM-DD'))
        ?.length > 0 ||
      currentDate.day() === 0 ||
      currentDate.day() === 6
    ) {
      currentDate = currentDate.add(1, 'days')
      findNextAvailableDate(currentDate)
    } else {
      nextAvailableDate = moment(currentDate)
    }

    if (nextAvailableDate) return nextAvailableDate
  }

  useEffect(() => {
    if (advisoryTime && !advisoryLoading) {
      const defaultDate = findNextAvailableDate()
      setDate(moment(defaultDate))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisoryTime, advisoryLoading])

  if (error || advisoryTimeError) return <Page404 />

  const handleDateChange = (e) => {
    setDate(moment(e))
  }

  const openAdvisoryModal = () => {
    setOpenModal(!openModal)
    setClientData({
      name: currentUser?.firstName + ' ' + currentUser?.lastName,
      date: moment(date).format('DD-MM-YYYY'),
    })
    if (
      !date.isBetween(
        moment(advisoryTime?.getAdvisoryTime?.startTime, 'hh:mm A'),
        moment(advisoryTime?.getAdvisoryTime?.endTime, 'hh:mm A')
      )
    ) {
      setIsTimeUp(true)
    } else {
      setIsTimeUp(false)
    }
  }

  const closeModal = () => {
    setIsTimeUp(false)
    setOpenModal(false)
  }

  const generate = async () => {
    if (!isTimeUp) {
      return client
        .mutate({
          mutation: GENERATE_ADVISORY,
          variables: {
            data: {
              clientCode: currentUser?.clientCode,
              plan: currentUser?.plan?.name,
              exposure: currentUser?.initialExposure,
              initialExposure: currentUser?.initialExposure,
              isNewClient: true,
            },
          },
        })
        .then((res) => {
          console.log('Advisory Generated')
          return true
        })
        .catch((err) => {
          console.log(err)
          setAdvisoryLoading(false)
          setOpenModal(false)
          return false
        })
    } else {
      return true
    }
  }

  const createAdvisory = async () => {
    setAdvisoryLoading(true)
    generate().then((res) => {
      if (res) {
        client
          .mutate({
            mutation: CREATE_BOOK_ADVISORY,
            variables: {
              data: {
                userId: userData?.id,
                advisoryDate: moment(date).format(),
                isAdvisoryBooked: true,
                isClientLogin: isTimeUp ? false : true,
                isClientAcceptAdvisory: false,
                isTimeUp,
              },
            },
          })
          .then((res) => {
            let data = { isBookingAdvisoryDateSelected: true }
            if (!isTimeUp) {
              if (currentUser?.isNewAuaAdvisoryGenerate) {
                data.newAuaAdvisoryCompletedSteps = 'CONGRATULATION'
              } else {
                data.completedSteps = 'CONGRATULATION'
              }
            } else {
              setIsAdvisoryBooked(true)
            }
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  data,
                  id: userData?.id,
                },
              })
              .then((response) => {
                setMandateId(response.data.updateUser?.mandateId)
                if (!isTimeUp) {
                  client.mutate({
                    mutation: COMPLETE_STEP,
                    variables: {
                      data: {
                        step: 'CONGRATULATION',
                        from: 'WEB',
                      },
                    },
                  })
                  Modal.info({
                    content: (
                      <div className='info_modal'>
                        <div className='info_modal_div'>
                          <p className='info_modal_text'>
                            {'Dear' +
                              ' ' +
                              currentUser?.firstName +
                              ' ' +
                              currentUser?.lastName}
                          </p>
                          <span className='info_modal_text'>
                            Just a few seconds
                          </span>
                          <span className='info_model_timer_text'>
                            We are processing the advisory.
                            <Countdown
                              format='ss'
                              value={Date.now() + 10 * 1000}
                            />
                          </span>
                        </div>
                      </div>
                    ),
                    icon: null,
                    okButtonProps: { style: { display: 'none' } },
                  })
                  setTimeout(() => {
                    setUserData(response.data.updateUser)
                    dispatch({
                      type: 'CURRENT_USER',
                      data: response.data.updateUser,
                    })
                    Modal.destroyAll()
                    setAdvisoryLoading(false)
                    setIsTimeUp(false)
                    setOpenModal(false)
                    next()
                  }, 10000)
                } else {
                  dispatch({
                    type: 'CURRENT_USER',
                    data: response.data.updateUser,
                  })
                  setAdvisoryLoading(false)
                  setIsTimeUp(false)
                  setOpenModal(false)
                }
              })
              .catch((error) => {
                console.log(error)
                setAdvisoryLoading(false)
                setIsTimeUp(false)
                setOpenModal(false)
              })
          })
          .catch((err) => {
            console.log(err)
            setIsTimeUp(false)
            setAdvisoryLoading(false)
            setOpenModal(false)
          })
      }
    })
  }

  const submitAdvisory = () => {
    createAdvisory()
  }

  return (
    <>
      {(!loading || !advisoryTimeLoading || amcAmount > 0) && (
        <div className='congratulations_page'>
          <div>
            <div className='header_logo_image'>
              <img
                className='fin_logo_div'
                src='https://finideas-india.blr1.digitaloceanspaces.com/Finideas-black-png.png'
                alt='Finideas'
              />
            </div>
            {isAdvisoryBooked &&
            userData &&
            userData?.isBookingAdvisoryDateSelected &&
            (userData?.completedSteps !== 'CONGRATULATION' ||
              userData?.newAuaAdvisoryCompletedSteps !== 'CONGRATULATION') ? (
              <BookedAdvisorySlot
                mandateId={mandateId}
                amcAmount={amcAmount}
                dateData={dates}
              />
            ) : (
              <div className='congratulations_div'>
                <img src={WorldImage} alt='Finideas' width={350} height={200} />
                <div className='congratulations_main_div'>
                  <b>*** !!! Congratulations !!! ***</b>
                </div>
                <div className='congratulations_sub_div'>
                  Thank you for selecting Finideas Investment Advisor Private
                  Limited as your trusted partner for your financial planning
                  journey towards achieving financial freedom
                </div>
                <div className='congratulations_date_select'>
                  <div className='congratulations_date_select_sub_div'>
                    Would you like to move forward with the advisory? Kindly
                    select the slot for advisory : &nbsp;
                  </div>
                  <DatePicker
                    className='advisory_select_date'
                    style={{ borderRadius: '8px' }}
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                    format={'DD-MM-YYYY'}
                    value={date}
                    allowClear={false}
                    dropdownClassName='custom-dropdown-class'
                  />
                </div>
                {(!moment().isBetween(
                  moment(advisoryTime?.getAdvisoryTime?.startTime, 'hh:mm A'),
                  moment(advisoryTime?.getAdvisoryTime?.endTime, 'hh:mm A')
                ) ||
                  isDisableDate) && (
                  <div className='congratulations_error_div'>
                    Advisory sessions will only be available from &nbsp;
                    {moment(
                      advisoryTime?.getAdvisoryTime?.startTime,
                      'hh:mm A'
                    ).format('LT')}
                    &nbsp;to &nbsp;
                    {moment(
                      advisoryTime?.getAdvisoryTime?.endTime,
                      'hh:mm A'
                    ).format('LT')}
                    . Please choose a different slot for your advisory session.
                  </div>
                )}

                <div className='congratulations_next_btn_div'>
                  <Button
                    type='primary'
                    className='congratulations_next_btn'
                    onClick={openAdvisoryModal}
                  >
                    Next
                  </Button>
                  <div className='faqs-icon-div'>
                    <Link
                      to='/faqs'
                      className='nav-text'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <div className='faqs-icon'>
                        <QuestionOutlined
                          style={{ color: '#fff', fontWeight: 'bold' }}
                        />
                      </div>
                    </Link>
                  </div>
                </div>

                {openModal && (
                  <BookAdvisoryModal
                    isModalOpen={openModal}
                    onCancel={closeModal}
                    isTimeUp={isTimeUp}
                    data={clientData}
                    onCreate={submitAdvisory}
                    loading={advisoryLoading}
                    advisoryTime={advisoryTime?.getAdvisoryTime}
                    amcAmount={amcAmount}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Congratulations
